<template>
  <section
    id="pricing"
    class="px-3"
  >
    <section-header
      header=""
      sub-header="Teenused"
      text="Eesti Äriarhiiv on Eesti kapitalil põhinev ettevõte, mis on asutatud 2000. aastal pika töökogemusega arhivaaride poolt. Meie põhitegevuseks on arhiivteenuste osutamine. Eesti Äriarhiiv osutab arhiiviteenuseid üle 200 ettevõttele ja asutusele, mille raames tagatakse dokumentide kompleksne haldus alates nende vastuvõtmisest arhiivi kuni dokumentide kontrollitud hävitamiseni peale nende säilitustähtaja möödumist"
    />

    <v-container
      mt-5
      pa-0
    >
      <v-row>
        <v-col
          v-for="(plan, i) in plans"
          :key="i"
          cols="12"
          md="4"
        >
          <v-card
            :elevation="i === 1 ? 12 : 0"
            :outlined="i !== 1"
            class="v-card--plan text-center pb-3"
          >
            <v-toolbar
              color="grey lighten-5"
              flat
            >
              <v-spacer />
              <div v-text="plan.name" />
              <v-spacer />
            </v-toolbar>
            <v-card-text class="mb-4">
              <div
                v-for="(feature, j) in plan.features"
                :key="j"
                v-text="feature"
              />
            </v-card-text>
            <v-card-text>
              <v-btn
                :color="i === 1 ? 'success' : 'primary'"
                depressed
                tile
                href="mailto:arhiiv@eba.ee?subject=Infop%C3%A4ring teie veebilehelt"
                target="_blank"
              >
                Küsi pakkumist
              </v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
  export default {
    data: () => ({
      frequency: 0,
      plans: [
        {
          name: 'Mahukas pakett',
          features: [
            'Põhjalik ja detailne dokumentide',
            'kirjeldamine. Mõeldud riigiasutustele'
          ]
        },
        {
          name: 'Lihtne pakett',
          features: [
            'Praktiliine, lihtne ja kiire arhiivkorrastus.',
            'Mõeldud eraettevõtetele'
          ]
        },
        {
          name: 'Individuaalne pakett',
          features: [
            'Koostame töödekooseisu',
            'vastavalt Teie vajadustele'
          ]
        }
      ]
    })
  }
</script>

<style lang="sass">
  .v-card--plan
    &__price
      div
        vertical-align: text-bottom

        > sup
          vertical-align: super

        > span
          vertical-align: middle

        + span
          vertical-align: super
</style>
